
import { defineComponent } from 'vue';
import Btn from '@/components/Btn.vue';
import PageHeader from '@/components/PageHeader.vue';
import BackBtn from '@/components/BackBtn.vue';
import json2022 from '@/assets/data/works2022.json';
import json2021 from '@/assets/data/works2021.json';
import json2020 from '@/assets/data/works2020.json';
import json2019 from '@/assets/data/works2019.json';
import json2018 from '@/assets/data/works2018.json';
import json2017 from '@/assets/data/works2017.json';
import json2016 from '@/assets/data/works2016.json';
import json2015 from '@/assets/data/works2015.json';
import json2014 from '@/assets/data/works2014.json';
import json2013 from '@/assets/data/works2013.json';
import json2012 from '@/assets/data/works2012.json';
import json2011 from '@/assets/data/works2011.json';
import json2010 from '@/assets/data/works2010.json';

class WorkDetail {
  id = '';
  year = '';
  date = '';
  title = '';
  prev = '';
  next = '';
  now = 0;
  total = 0; 
}

export default defineComponent({
  name: 'WorksDetail',
  components: {
    Btn, BackBtn, PageHeader
  },
  data(){
    return {
      id : this.$route.params.id, // ex: 2021-001
      currentWork: new WorkDetail(),
      isLoaded: false
    }
  },
  computed: {
    works2022(){ return json2022.filter(function(w:any){ return w.display === true }); },
    works2021(){ return json2021.filter(function(w:any){ return w.display === true }); },
    works2020(){ return json2020.filter(function(w:any){ return w.display === true }); },
    works2019(){ return json2019.filter(function(w:any){ return w.display === true }); },
    works2018(){ return json2018.filter(function(w:any){ return w.display === true }); },
    works2017(){ return json2017.filter(function(w:any){ return w.display === true }); },
    works2016(){ return json2016.filter(function(w:any){ return w.display === true }); },
    works2015(){ return json2015.filter(function(w:any){ return w.display === true }); },
    works2014(){ return json2014.filter(function(w:any){ return w.display === true }); },
    works2013(){ return json2013.filter(function(w:any){ return w.display === true }); },
    works2012(){ return json2012.filter(function(w:any){ return w.display === true }); },
    works2011(){ return json2011.filter(function(w:any){ return w.display === true }); },
    works2010(){ return json2010.filter(function(w:any){ return w.display === true }); },
  },
  mounted() {
    this.setCurrentWork();
  },
  updated() {
    this.setCurrentWork();
    this.setPrefetch();
  },
  methods: {
    reset(){
      this.isLoaded = false
    },
    loaded(){
      setTimeout(()=>{
        this.isLoaded = true;
      }, 200);
    },
    setCurrentWork(){
      this.id = this.$route.params.id;
      const arr = this.getArray(String(this.id).split('-')[0]);
      for(let i=0; i<arr!.length; i++){
        const w = arr![i];
        if(w.id === this.id){
          this.currentWork.id = w.id;
          this.currentWork.year = w.year;
          this.currentWork.date = w.date;
          this.currentWork.title = w.title;
          this.currentWork.now = i;
          this.currentWork.prev = i>0 ? arr![i-1].id : '';
          this.currentWork.next = i<arr!.length-1 ? arr![i+1].id : '';
          this.currentWork.total = arr!.length;
          break;
        }
      }
    },
    getArray(year:string){
      switch(Number(year)){
        case 2022: return this.works2022;
        case 2021: return this.works2021;
        case 2020: return this.works2020;
        case 2019: return this.works2019;
        case 2018: return this.works2018;
        case 2017: return this.works2017;
        case 2016: return this.works2016;
        case 2015: return this.works2015;
        case 2014: return this.works2014;
        case 2013: return this.works2013;
        case 2012: return this.works2012;
        case 2011: return this.works2011;
        case 2010: return this.works2010;
      }
    },
    setPrefetch(){
      if(document.getElementById('next-image') != null) document.getElementById('next-image')!.remove()
      if(document.getElementById('prev-image') != null) document.getElementById('prev-image')!.remove()
      let head = document.getElementsByTagName('head').item(0)
      if(this.currentWork.next != '')
        head!.appendChild(_tag(this.currentWork.year, this.currentWork.next, 'next-image'))
      if(this.currentWork.prev != '')
        head!.appendChild(_tag(this.currentWork.year, this.currentWork.prev, 'prev-image'))
      function _tag(year:string, id:string, name:string){
        const url = '/img/illust/'+ year + '/' + id.split('-')[1] +'/main.png'
        let link = document.createElement('link')
        link.rel = 'prefetch'
        link.href = url
        link.as = 'fetch'
        link.type = 'image/png'
        link.id = name
        return link
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    document.querySelector('html')?.classList.add('works-detail-page');
    next();
  },
  beforeRouteLeave (to, from, next) {
    document.querySelector('html')?.classList.remove('works-detail-page');
    next();
  }
});
