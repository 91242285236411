import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "detail__body" }
const _hoisted_2 = { class: "detail__img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "detail__txtbox" }
const _hoisted_5 = { class: "date" }
const _hoisted_6 = { class: "ttl" }
const _hoisted_7 = { class: "detail__btn-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_BackBtn = _resolveComponent("BackBtn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader, { title: "WORKS" }),
    _createElementVNode("div", {
      class: _normalizeClass(["detail", {'is--loaded': _ctx.isLoaded}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: '/img/illust/'+ _ctx.currentWork.year + '/' + _ctx.currentWork.id.split('-')[1] +'/main.png',
            alt: "",
            onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loaded && _ctx.loaded(...args)))
          }, null, 40, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.currentWork.year) + "." + _toDisplayString(_ctx.currentWork.date), 1),
          _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.currentWork.title), 1)
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.currentWork.prev != '')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "detail__btn detail__btn--prev",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
          }, [
            _createVNode(_component_Btn, {
              layout: "left",
              url: _ctx.currentWork.prev,
              text: "PREV"
            }, null, 8, ["url"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.currentWork.next != '')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "detail__btn detail__btn--next",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
          }, [
            _createVNode(_component_Btn, {
              layout: "right",
              url: _ctx.currentWork.next,
              text: "NEXT"
            }, null, 8, ["url"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_BackBtn, {
      url: '/works/' + _ctx.currentWork.year,
      text: "BACK"
    }, null, 8, ["url"])
  ], 64))
}